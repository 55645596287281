import React, {Component} from 'react';
import { useParams, withRouter } from 'react-router-dom';
import Stores from './components/Store/Stores';
import NewStore from './components/Store/NewStore';
import Store from './components/Store/Store';
import NewBranch from './components/Branch/NewBranch';
import Branches from './components/Branch/Branches';
import NewProduct from './components/Product/NewProduct';
import Products from './components/Product/Products';
import Categories from './components/Category/Categories';
import NewCategory from './components/Category/NewCategory';
import AboutUs from './components/Store/AboutUs';
import StoreFooter from './components/Store/StoreFooter';
import StoreCheckpage from './components/Store/StoreCheckpage';
import StoreNewClient from './components/Store/StoreNewClient';
import BranchHome from './components/Branch/BranchHome';
import BranchTransactions from './components/Branch/BranchTransactions';
import Order from './components/Branch/Order';
import NewUser from './components/Users/NewUser';
import Users from './components/Users/Users';
import NewSale from './components/Sales/NewSale';
import Sales from './components/Sales/Sales';
import ClientTypes from './components/ClientType/ClientTypes';
import NewClientType from './components/ClientType/NewClientType';
import ClientTypeUpdate from './components/ClientType/ClientTypeUpdate';
import NewClientTypeUpdate from './components/ClientType/NewClientTypeUpdate';
import PointOfSales from './components/PointOfSale/PointOfSales';
import NewPointOfSale from './components/PointOfSale/NewPointOfSale';
import PointOfSaleTransactions from './components/PointOfSale/PointOfSaleTransactions';
import NewClient from './components/Client/NewClient';
import Clients from './components/Client/Clients';
import UploadClients from './components/Client/UploadClients';
import UploadTransactions from './components/Client/UploadTransactions';
import UploadCardNumbers from './components/Client/UploadCardNumbers';
import UploadGiftcards from './components/Giftcard/UploadGiftcards';
import Giftcards from './components/Giftcard/Giftcards';
import Lots from './components/Lot/Lots';
import NewLot from './components/Lot/NewLot';
import LotParticipants from './components/Lot/LotParticipants';
import Polls from './components/Poll/Polls';
import NewPoll from './components/Poll/NewPoll';
import PollQuestions from './components/PollQuestions/PollQuestions';
import NewPollQuestion from './components/PollQuestions/NewPollQuestion';
import PollAnswers from './components/PollAnswers/PollAnswers'
import CuponChecks from './components/CuponCheck/CuponChecks';
import NewCuponCheck from './components/CuponCheck/NewCuponCheck';
import StoreTransactions from './components/Store/StoreTransactions';
import StoreReport from './components/Store/StoreReport';
import Branch from './components/Branch/Branch';
import NewCommunication from './components/Communication/NewCommunication';
import Communications from './components/Communication/Communications';
import CampaignReport from './components/Communication/CampaignReport';
import Banners from './components/Banner/Banners';
import NewBanner from './components/Banner/NewBanner';
import Rewards from './components/Rewards/Rewards';
import NewReward from './components/Rewards/NewReward';
import Stamps from './components/Stamps/Stamps';
import NewStamp from './components/Stamps/NewStamp';

const NewUserWithHistory = withRouter(NewUser)
const UsersWithHistory = withRouter(Users)
const OrderWithHistory = withRouter(Order)
const StoresWithHistory = withRouter(Stores)
const NewStoreWithHistory = withRouter(NewStore)
const StoreReportWithHistory = withRouter(StoreReport)
const StoreWithHistory = withRouter(Store)
const BranchesWithHistory = withRouter(Branches)
const BranchWithHistory = withRouter(Branch)
const AboutUsWithHistory = withRouter(AboutUs)
const NewBranchWithHistory = withRouter(NewBranch)
const BranchHomeWithHistory = withRouter(BranchHome)
const CategoriesWithHistory = withRouter(Categories)
const NewCategoryWithHistory = withRouter(NewCategory)
const ProductsWithHistory = withRouter(Products)
const NewProductWithHistory = withRouter(NewProduct)
const SalesWithHistory = withRouter(Sales)
const NewSaleWithHistory = withRouter(NewSale)
const StoreClientTypesWithHistory = withRouter(ClientTypes)
const NewClientTypeWithHistory = withRouter(NewClientType)
const StoreClientTypeUpdateWithHistory = withRouter(ClientTypeUpdate)
const NewClientTypeUpdateWithHistory = withRouter(NewClientTypeUpdate)
const PointOfSalesWithHistory = withRouter(PointOfSales)
const NewPointOfSaleWithHistory = withRouter(NewPointOfSale)
const PointOfSaleTransactionsWithHistory = withRouter(PointOfSaleTransactions)
const BranchTransactionsWithHistory = withRouter(BranchTransactions)
const NewClientWithHistory = withRouter(NewClient)
const ClientsWithHistory = withRouter(Clients)
const UploadClientsWithHistory = withRouter(UploadClients)
const UploadTransactionsWithHistory = withRouter(UploadTransactions)
const UploadCardNumbersWithHistory = withRouter(UploadCardNumbers)
const UploadGiftcardsWithHistory = withRouter(UploadGiftcards)
const GiftcardsWithHistory = withRouter(Giftcards)
const LotsWithHistory = withRouter(Lots)
const NewLotWithHistory = withRouter(NewLot)
const LotParticipantsWithHistory = withRouter(LotParticipants)
const PollsWithHistory = withRouter(Polls)
const NewPollWithHistory = withRouter(NewPoll)
const PollQuestionsWithHistory = withRouter(PollQuestions)
const NewPollQuestionWithHistory = withRouter(NewPollQuestion)
const StoreFooterWithHistory = withRouter(StoreFooter)
const StoreCheckpageWithHistory = withRouter(StoreCheckpage)
const StoreNewClientPageWithHistory = withRouter(StoreNewClient)
const NewCuponCheckWithHistory = withRouter(NewCuponCheck)
const CuponChecksWithHistory = withRouter(CuponChecks)
const PollAnswersWithHistory = withRouter(PollAnswers)
const StoreTransactionsWithHistory = withRouter(StoreTransactions)
const NewCommunicationWithHistory = withRouter(NewCommunication)
const CommunicationsWithHistory = withRouter(Communications)
const CampaignReportComponent = withRouter(CampaignReport)
const NewBannerWithHistory = withRouter(NewBanner)
const BannersWithHistory = withRouter(Banners)
const NewRewardWithHistory = withRouter(NewReward)
const RewardsWithHistory = withRouter(Rewards)
const NewStampWithHistory = withRouter(NewStamp)
const StampsWithHistory = withRouter(Stamps)

function OpenNewUser() {
    return(<NewUserWithHistory admin={true} stores={true} branches={true} orders={true} products={true} sales={true} />);
}

function OpenNewStoreUser() {
    let { storeId } = useParams();
    return(<NewUserWithHistory admin={false} stores={false} storeId={storeId} branches={true} orders={true} products={true} sales={true} />);
}

function OpenNewBranchUser() {
    let { storeId, branchId } = useParams();
    return(<NewUserWithHistory admin={false} stores={false} storeId={storeId} branchId={branchId} branches={false} orders={true} products={true} sales={true} />);
}

function OpenEditUser() {
    let { userId } = useParams();
    return(<NewUserWithHistory userId={userId} admin={true} stores={true} branches={true} orders={true} products={true} sales={true} />)
}

function OpenEditStoreUser() {
    let { storeId, userId } = useParams();
    return(<NewUserWithHistory userId={userId} admin={false} stores={false} storeId={storeId} branches={true} orders={true} products={true} sales={true} />)
}

function OpenEditBranchUser() {
    let { storeId, branchId, userId } = useParams();
    return(<NewUserWithHistory userId={userId} admin={false} stores={false} storeId={storeId} branchId={branchId} branches={false} orders={true} products={true} sales={true} />)
}

export default [
    { path: "/users/new", Component: NewUserWithHistory, name: "Nuevo Usuario Backoffice" },
    { path: "/users/:userId", Component: OpenEditUser, name: "Editar Usuario Backoffice" },
    { path: "/users", Component: UsersWithHistory, name: "Usuarios Backoffice" },
    { path: "/stores", Component: StoresWithHistory, name: "Empresas" },
    { path: "/stores/:storeId/users/new", Component: OpenNewStoreUser, name: "Nuevo Usuario" },
    { path: "/stores/:storeId/users", Component: UsersWithHistory, name: `Usuarios $STORE_NAME` },
    { path: "/stores/:storeId/users/:userId", Component: OpenEditStoreUser, name: `Detalle Usuario` },
    { path: "/stores/new", Component: NewStoreWithHistory, name: "Nueva Marca" },
    { path: "/stores/:storeId/edit", Component: NewStoreWithHistory, name: "Editar" },
    { path: "/stores/:storeId", Component: StoreWithHistory, name: "$STORE_NAME" },
    { path: "/stores/:storeId/branches", Component: BranchesWithHistory, name: "Sucursales" },
    { path: "/stores/:storeId/report", Component: StoreReportWithHistory, name: "Reportes" },
    
    // Client Types
    { path: "/stores/:storeId/client_types", Component: StoreClientTypesWithHistory, name: "Tipos de cliente" },
    { path: "/stores/:storeId/client_types/new", Component: NewClientTypeWithHistory, name: "Nuevo tipo de cliente" },
    { path: "/stores/:storeId/client_types/:clientTypeId/edit", Component: NewClientTypeWithHistory, name: "Editar tipo de cliente" },
    { path: "/stores/:storeId/client_types_updates", Component: StoreClientTypeUpdateWithHistory, name: "Cambios de Categoria" },
    { path: "/stores/:storeId/client_types_updates/new", Component: NewClientTypeUpdateWithHistory, name: "Nuevo Cambio de Categoria" },
    { path: "/stores/:storeId/branches/:branchId/client_types/:clientTypeId/edit", Component: NewClientTypeWithHistory, name: "Editar tipo de cliente" },
    

    { path: "/stores/:storeId/about", Component: AboutUsWithHistory, name: "Sobre nosotros" },
    { path: "/stores/:storeId/ticket_footer", Component: StoreFooterWithHistory, name: "Configuración Ticket" },
    { path: "/stores/:storeId/checkpage", Component: StoreCheckpageWithHistory, name: "Configuración Sitio Consulta de Saldo" },
    { path: "/stores/:storeId/newclientpage", Component: StoreNewClientPageWithHistory, name: "Configuración Sitio Nuevos Clientes" },
    { path: "/stores/:storeId/transactions", Component: StoreTransactionsWithHistory, name: "Transacciones" },
    { path: "/stores/:storeId/branches/new", Component: NewBranchWithHistory, name: "Nueva sucursal" },
    { path: "/stores/:storeId/branches/:branchId", Component: BranchWithHistory, name: "$BRANCH_NAME" },
    { path: "/stores/:storeId/branches/:branchId/edit", Component: NewBranchWithHistory, name: "Editar" },
    { path: "/stores/:storeId/branches/:branchId/transactions", Component: BranchTransactionsWithHistory, name: "Transacciones" },
    { path: "/stores/:storeId/branches/:branchId/home", Component: BranchHomeWithHistory, name: "" },
    { path: "/stores/:storeId/branches/:branchId/users/new", Component: OpenNewBranchUser, name: "Nuevo Usuario" },
    { path: "/stores/:storeId/branches/:branchId/users", Component: UsersWithHistory, name: "Usuarios" },
    { path: "/stores/:storeId/branches/:branchId/users/:userId", Component: OpenEditBranchUser, name: "Detalle Usuario" },
    { path: "/stores/:storeId/branches/:branchId/categories", Component: CategoriesWithHistory, name: "Categorías" },
    { path: "/stores/:storeId/branches/:branchId/categories/new", Component: NewCategoryWithHistory, name: "Nueva categoría" },
    { path: "/stores/:storeId/branches/:branchId/categories/:categoryId", Component: NewCategoryWithHistory, name: "Editar categoría" },
    { path: "/stores/:storeId/branches/:branchId/client_types", Component: StoreClientTypesWithHistory, name: "Tipos de cliente" },
    { path: "/stores/:storeId/branches/:branchId/pos", Component: PointOfSalesWithHistory, name: "Puntos de venta" },
    { path: "/stores/:storeId/branches/:branchId/pos/new", Component: NewPointOfSaleWithHistory, name: "Nuevo Punto de venta" },
    { path: "/stores/:storeId/branches/:branchId/pos/:posId/edit", Component: NewPointOfSaleWithHistory, name: "Editar Punto de venta" },
    { path: "/stores/:storeId/branches/:branchId/pos/:posId", Component: NewPointOfSaleWithHistory, name: "$POS_NAME" },
    { path: "/stores/:storeId/branches/:branchId/pos/:posId/transactions", Component: PointOfSaleTransactionsWithHistory, name: "Transacciones" },
    { path: "/stores/:storeId/clients", Component: ClientsWithHistory, name: "Clientes" },
    { path: "/stores/:storeId/clients/new", Component: NewClientWithHistory, name: "Nuevo cliente" },
    { path: "/stores/:storeId/clients/upload", Component: UploadClientsWithHistory, name: "Carga masiva clientes" },
    { path: "/stores/:storeId/transactions/upload", Component: UploadTransactionsWithHistory, name: "Carga masiva Transacciones" },
    { path: "/stores/:storeId/clients/:clientID/edit", Component: NewClientWithHistory, name: "Editar cliente" },
    { path: "/stores/:storeId/card_numbers/upload", Component: UploadCardNumbersWithHistory, name: "Carga masiva tarjetas" },
    { path: "/stores/:storeId/giftcards/upload", Component: UploadGiftcardsWithHistory, name: "Carga masiva giftcards" },
    { path: "/stores/:storeId/giftcards", Component: GiftcardsWithHistory, name: "Giftcards" },
    { path: "/stores/:storeId/categories", Component: CategoriesWithHistory, name: "Categorías" },
    { path: "/stores/:storeId/categories/new", Component: NewCategoryWithHistory, name: "Nueva categoría" },
    { path: "/stores/:storeId/categories/:categoryId", Component: NewCategoryWithHistory, name: "Editar categoría" },
    { path: "/stores/:storeId/branches/:branchId/categories/:categoryId/products", Component: ProductsWithHistory, name: "Productos" },
    { path: "/stores/:storeId/branches/:branchId/categories/:categoryId/products/new", Component: NewProductWithHistory, name: "Nuevo Producto" },
    { path: "/stores/:storeId/branches/:branchId/categories/:categoryId/products/:productId", Component: NewProductWithHistory, name: "Editar Producto" },
    { path: "/stores/:storeId/categories/:categoryId/products", Component: ProductsWithHistory, name: "Productos" },
    { path: "/stores/:storeId/categories/:categoryId/products/:productId", Component: NewProductWithHistory, name: "Editar Producto" },
    { path: "/stores/:storeId/categories/:categoryId/products/new", Component: NewProductWithHistory, name: "Nuevo Producto" },
    { path: "/stores/:storeId/branches/:branchId/sales", Component: SalesWithHistory, name: "Promociones" },
    { path: "/stores/:storeId/branches/:branchId/sales/new", Component: NewSaleWithHistory, name: "Nueva promoción" },
    { path: "/order/:orderId", Component: OrderWithHistory, name: "Detalle Pedido" },
    
    // Lots
    { path: "/stores/:storeId/branches/:branchId/lots", Component: LotsWithHistory, name: "Sorteos" },
    { path: "/stores/:storeId/branches/:branchId/lots/new", Component: NewLotWithHistory, name: "Nuevo Sorteo" },
    { path: "/stores/:storeId/branches/:branchId/lots/:lotId", Component: NewLotWithHistory, name: "$LOT_NAME" },
    { path: "/stores/:storeId/branches/:branchId/lots/:lotId/edit", Component: NewLotWithHistory, name: "Editar Sorteo" },
    { path: "/stores/:storeId/branches/:branchId/lots/:lotId/participants", Component: LotParticipantsWithHistory, name: "Participantes" },
    
    // Polls
    { path: "/stores/:storeId/branches/:branchId/polls", Component: PollsWithHistory, name: "Encuestas" },
    { path: "/stores/:storeId/branches/:branchId/polls/new", Component: NewPollWithHistory, name: "Nueva Encuesta" },
    { path: "/stores/:storeId/branches/:branchId/polls/:pollId", Component: NewPollWithHistory, name: "$POLL_NAME" },
    { path: "/stores/:storeId/branches/:branchId/polls/:pollId/edit", Component: NewPollWithHistory, name: "Editar Encuenta" },
    { path: "/stores/:storeId/branches/:branchId/polls/:pollId/questions", Component: PollQuestionsWithHistory, name: "Preguntas" },
    { path: "/stores/:storeId/branches/:branchId/polls/:pollId/answers", Component: PollAnswersWithHistory, name: "Respuestas" },
    { path: "/stores/:storeId/branches/:branchId/polls/:pollId/questions/new", Component: NewPollQuestionWithHistory, name: "Nueva Pregunta" },
    { path: "/stores/:storeId/branches/:branchId/polls/:pollId/questions/:pollQuestionId/edit", Component: NewPollQuestionWithHistory, name: "Editar Pregunta" },
    
    // CuponChecks
    { path: "/stores/:storeId/branches/:branchId/cupon_checks", Component: CuponChecksWithHistory, name: "Cupon Check" },
    { path: "/stores/:storeId/branches/:branchId/cupon_checks/new", Component: NewCuponCheckWithHistory, name: "Nuevo Cupon Check" },
    { path: "/stores/:storeId/branches/:branchId/cupon_checks/:cuponCheckId", Component: NewCuponCheckWithHistory, name: "$CUPON_CHECK_NAME" },
    { path: "/stores/:storeId/branches/:branchId/cupon_checks/:cuponCheckId/edit", Component: NewCuponCheckWithHistory, name: "Editar Cupon Check" },
    
    // Communications
    { path: "/stores/:storeId/communications/new", Component: NewCommunicationWithHistory, name: "Nueva Comunicación" },
    { path: "/stores/:storeId/communications/:communicationId/edit", Component: NewCommunicationWithHistory, name: "Editar Comunicación" },
    { path: "/stores/:storeId/communications/:email_subject/campaign_report", Component: CampaignReportComponent, name: "Ver reporte de campaña" },
    { path: "/stores/:storeId/communications", Component: CommunicationsWithHistory, name: "Comunicaciones" },
    
    // Banners
    { path: "/stores/:storeId/banners", Component: BannersWithHistory, name: "Banners" },
    { path: "/stores/:storeId/banners/new", Component: NewBannerWithHistory, name: "Nuevo Banner" },
    { path: "/stores/:storeId/banners/:bannerId", Component: NewBannerWithHistory, name: "Banner" },
    { path: "/stores/:storeId/banners/:bannerId/edit", Component: NewBannerWithHistory, name: "Editar Banner" },

    // Rewards
    { path: "/stores/:storeId/rewards", Component: RewardsWithHistory, name: "Premios" },
    { path: "/stores/:storeId/rewards/new", Component: NewRewardWithHistory, name: "Nuevo Premio" },
    { path: "/stores/:storeId/rewards/:rewardId", Component: NewRewardWithHistory, name: "Premio" },
    { path: "/stores/:storeId/rewards/:rewardId/edit", Component: NewRewardWithHistory, name: "Editar Premio" },

    // Stamps
    { path: "/stores/:storeId/stamps", Component: StampsWithHistory, name: "Estampas" },
    { path: "/stores/:storeId/stamps/new", Component: NewStampWithHistory, name: "Nueva Estampa" },
    { path: "/stores/:storeId/stamps/:stampId", Component: NewStampWithHistory, name: "Estampa" },
    { path: "/stores/:storeId/stamps/:stampId/edit", Component: NewStampWithHistory, name: "Editar Estampa" },
];

