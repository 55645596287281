import React, { Component, useRef } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { NavLink, Redirect } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { EmailEditor } from 'react-email-editor';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const emailTypeWelcome = "WELCOME"
const emailTypeBirthDay = "BIRTHDAY"
const emailTypeTransaction = "TRANSACTION"
const emailTypeUpdateCategory = "UPDATE_CATEGORY"
const emailTypeInactiveUser = "INACTIVE_USER"
const emailTypeCampaign = "CAMPAIGN"

class NewCommunication extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            email_subject: '',
            email_type: '',
            someChanges: false,
            editor: null,
            open: false,
            message: '',
            urlToRedirect: null,
            age_0_18: false,
        age_19_25: false,
        age_26_30: false,
        age_31_40: false,
        age_41_50: false,
        age_51_60: false,
        age_61_100: false,
        male: false,
        female: false,
        in: false,
        communication: null,
        inactive_days: 0,
        clientTypes: null,
        client_type_id: 0,
        start_date: '',
        to_email: '',
        send_now: false,
        is_valid_subject: true,
        }

        this.onChangeEmailType = this.onChangeEmailType.bind(this)
        this.onChangeEmailSubject = this.onChangeEmailSubject.bind(this)
        this.exportHtml = this.exportHtml.bind(this)
        this.testEmail = this.testEmail.bind(this)
        this.goToList = this.goToList.bind(this)
        this.onChangeAge_0_18 = this.onChangeAge_0_18.bind(this)
        this.onChangeAge_19_25 = this.onChangeAge_19_25.bind(this)
        this.onChangeAge_26_30 = this.onChangeAge_26_30.bind(this)
        this.onChangeAge_31_40 = this.onChangeAge_31_40.bind(this)
        this.onChangeAge_41_50 = this.onChangeAge_41_50.bind(this)
        this.onChangeAge_51_60 = this.onChangeAge_51_60.bind(this)
        this.onChangeAge_61_100 = this.onChangeAge_61_100.bind(this)
        this.onChangeMale = this.onChangeMale.bind(this)
        this.onChangeFemale = this.onChangeFemale.bind(this)
        this.onChangeIn = this.onChangeIn.bind(this)
        this.onChangeToEmail = this.onChangeToEmail.bind(this)
        this.onReady = this.onReady.bind(this)
        this.onChangeInactiveDays = this.onChangeInactiveDays.bind(this)
        this.onChangeStartDate = this.onChangeStartDate.bind(this)
        this.onChangeSendNow = this.onChangeSendNow.bind(this)
        this.onChangeClientType = this.onChangeClientType.bind(this)
        this.setClientTypes = this.setClientTypes.bind(this)
        this.lookForCommunication = this.lookForCommunication.bind(this)
    }

    onChangeClientType(e) {
        this.setState({ client_type_id: e.target.value, someChanges: true })
    }

    onChangeEmailType(e) {
        this.setState({ email_type: e.target.value, someChanges: true })
    }

    onChangeEmailSubject(e) {
        let subject = e.target.value
        this.setState({ email_subject: e.target.value, someChanges: true, is_valid_subject: true })
        if (this.state.email_type === emailTypeCampaign & (subject.includes("[") || subject.includes("]"))) {
            this.setState({is_valid_subject: false})
        }
    }

    onChangeToEmail(e) {
        this.setState({ to_email: e.target.value })
    }

    onChangeSendNow(e) {
        this.setState({ send_now: e.target.checked })
    }
    
    componentDidMount() {
        const { match: { params } } = this.props;

        var url = `${this.context.hubURL}/stores/${params.storeId}/client_types`;
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' }})
                .then(res => {
                    this.setClientTypes(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                })
        );
    }

    setClientTypes(data) {
        this.lookForCommunication(data.filter(ct => ct.deleted === 0));
    }

    lookForCommunication(clientTypes) {
        const { match: { params } } = this.props;

        if (params.communicationId != null) {
            let url = `${this.context.hubURL}/stores/${params.storeId}/communications/${params.communicationId}`
            trackPromise(
                axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                    .then(res => { 
                        var startDate = ""
                        if (res.data.start_date != "" && res.data.start_date != null) {
                            if (res.data.start_date.includes('.')) {
                                startDate = '';
                            } else if (res.data.start_date.includes('-')) {
                                let parts = res.data.start_date.split('-');
                                var timestamp = Date.parse(parts[1]+"-"+parts[2]+"-"+parts[0]+" 00:00:00");
                                if (isNaN(timestamp) == false) {
                                    startDate = new Date(timestamp);
                                }
                            } else {
                                let parts = res.data.start_date.split('/');
                                var timestamp = Date.parse(parts[2]+"-"+parts[1]+"-"+parts[0]+" 00:00:00");
                                if (isNaN(timestamp) == false) {
                                    startDate = new Date(timestamp);
                                }
                            }
                        }
                        
                        this.setState({ clientTypes: clientTypes, communication: res.data, inactive_days: res.data["inactive_days"], start_date: startDate, email_type: res.data["email_type"], email_content_json: res.data["email_content_json"], email_content: res.data["email_content"], email_subject: res.data["email_subject"], open: false }); 
                    })
                    .catch(function (error) { console.log(error); })
            );
        } else {
            this.setState({ clientTypes: clientTypes });
        }
    }

    onReady() {
        if (this.state.email_content_json !== undefined && this.state.email_content_json !== null && this.state.email_content_json !== "") {
            const json = JSON.parse(this.state.email_content_json);
            this.editor.loadDesign(json);
        }
    }

    testEmail(e) {
        if (this.state.to_email == '') {
            alert("Por favor ingresá un email");
        } else {
            this.editor.exportHtml(data => {
                const { design, html } = data
    
                const { match: { params } } = this.props;
    
                const object = {
                    email_content: html,
                    email_subject: this.state.email_subject,
                    email_type: this.state.email_type,
                    to_email: this.state.to_email,
                }
                
                trackPromise(
                    axios.post(`${this.context.hubURL}/stores/${params.storeId}/communications_test`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })                
                    // axios.post(`http://localhost:8080/stores/${params.storeId}/communications_test`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                    .then((res) => { alert("Email de prueba enviado."); })
                    .catch((error) => {
                        alert(error);
                }))
            })
        }
    }

    exportHtml(e) {
        e.preventDefault()

        this.editor.exportHtml(data => {
            const { design, html } = data

            const { match: { params } } = this.props;

            var genders = ""
            if (this.state.male) {
                genders += "M"
            }
            if (this.state.female) {
                genders += "F"
            }
            if (this.state.in) {
                genders += "I"
            }

            var ages = ""
            if(this.state.age_0_18) {
                ages += "0_18#"
            }
            if(this.state.age_19_25) {
                ages += "19_25#"
            }
            if(this.state.age_26_30) {
                ages += "26_30#"
            }
            if(this.state.age_31_40) {
                ages += "31_40#"
            }
            if(this.state.age_41_50) {
                ages += "41_50#"
            }
            if(this.state.age_51_60) {
                ages += "51_60#"
            }
            if(this.state.age_61_100) {
                ages += "61_100#"
            }

            var startDate = this.state.start_date;
            if (this.state.send_now) {
                startDate = format(new Date(), "yyyy-MM-dd")
            } else {
                try {
                    startDate = format(this.state.start_date, "yyyy-MM-dd")
                } catch(err) {
                    console.log("Error cambiando la fecha");
                }
            }

            const object = {
                email_content: html,
                email_content_json: JSON.stringify(design),
                email_subject: this.state.email_subject,
                email_type: this.state.email_type,
                genders: genders,
                ages: ages,
                inactive_days: this.state.inactive_days,
                start_date: startDate,
                client_type_id: this.state.client_type_id,
            }
            
            trackPromise(
                axios.post(`${this.context.hubURL}/stores/${params.storeId}/communications`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then((res) => { this.setState({ open: true, message: "Comunicación creada correctamente!" }) })
                .catch((error) => {
                    alert(error);
            })
        );

        })
      }

      onChangeAge_0_18(e) { this.setState({ age_0_18: e.target.checked, someChanges: true }) }
      onChangeAge_19_25(e) { this.setState({ age_19_25: e.target.checked, someChanges: true }) }
      onChangeAge_26_30(e) { this.setState({ age_26_30: e.target.checked, someChanges: true }) }
      onChangeAge_31_40(e) { this.setState({ age_31_40: e.target.checked, someChanges: true }) }
      onChangeAge_41_50(e) { this.setState({ age_41_50: e.target.checked, someChanges: true }) }
      onChangeAge_51_60(e) { this.setState({ age_51_60: e.target.checked, someChanges: true }) }
      onChangeAge_61_100(e) { this.setState({ age_61_100: e.target.checked, someChanges: true }) }

      onChangeMale(e) { this.setState({ male: e.target.checked, someChanges: true }) }
      onChangeFemale(e) { this.setState({ female: e.target.checked, someChanges: true }) }
      onChangeIn(e) { this.setState({ in: e.target.checked, someChanges: true }) }

      onChangeInactiveDays(e) { 
        this.setState({ inactive_days: e.target.value, someChanges: true })
    }

    onChangeStartDate(e) {
        this.setState({ start_date: e, someChanges: true })
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        let clientTypeSelect
        if (this.state.email_type === 'UPDATE_CATEGORY') {
            if (this.state.clientTypes == null) {
                clientTypeSelect = ""
            } else {
                clientTypeSelect = <div className="form-group col-md-4">
                    <label for="selectClientType">Tipo Cliente</label>
                    <select id="selectClientType" className="form-control" value={this.state.client_type_id} onChange={this.onChangeClientType}>
                        { this.state.clientTypes.map((clientType) => (
                            <option value={clientType.id}>{clientType.name}</option>
                        )) 
                        }
                    </select>
                </div>
            }
        }
        let maxDate = format(addMonths(new Date(), 12), "yyyy-MM-dd")
        let minDate = format(new Date(), "yyyy-MM-dd")

        var inactiveOptions = ""
        if (this.state.email_type === 'INACTIVE_USER') {
            inactiveOptions = <div className="form-row align-items-center">
                <div className="form-group col-md-4">
                    <label for="inputInactive">Días Inactivo</label>
                    <input className="form-control" type="number" id="inputInactive" placeholder="Días Inactivo" value={this.state.inactive_days} onChange={this.onChangeInactiveDays} />
                </div>
            </div>
        }

        var campaignFiltersDates, campaignFiltersGender, campaignFiltersAges = ""
        if (this.state.email_type === emailTypeCampaign) {
            campaignFiltersDates = <div className="form-row align-items-center">
            <div className="form-group col-md-4">
                <label for="inputDate">Fecha Inicio</label>
                <DatePicker id="inputDate" className="form-control" dateFormat="dd/MM/yyyy" disabled={this.state.send_now} selected={this.state.start_date} onChange={this.onChangeStartDate} maxDate={maxDate} minDate={minDate} />
            </div>
            <div className="form-group col-md-4 custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="send_now" checked={this.state.send_now} onClick={this.onChangeSendNow} />
                <label className="custom-control-label" for="send_now">Enviar ahora</label>
            </div>
        </div>
            campaignFiltersGender = <div className="form-row align-items-center">
            <div className="form-group col-md-6">
                <label for="genero">Genero</label>
                <div id="genero">
                    <div className="form-group custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="M" checked={this.state.male} onClick={this.onChangeMale} />
                            <label className="custom-control-label" for="M">Hombre</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="F" checked={this.state.female} onClick={this.onChangeFemale} />
                            <label className="custom-control-label" for="F">Mujer</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="I" checked={this.state.in} onClick={this.onChangeIn} />
                            <label className="custom-control-label" for="I">Indistinto</label>
                    </div>
                </div>
            </div>
        </div>
            campaignFiltersAges = <div className="form-row align-items-center">
            <div className="form-group col-md-6">
                <label for="edades">Edades</label>
                <div id="edades">
                    <div className="form-group custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="0_18" checked={this.state.age_0_18} onClick={this.onChangeAge_0_18} />
                            <label className="custom-control-label" for="0_18">0 - 18</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="19_35" checked={this.state.age_19_25} onClick={this.onChangeAge_19_25} />
                            <label className="custom-control-label" for="19_35">19 - 25</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="26_30" checked={this.state.age_26_30} onClick={this.onChangeAge_26_30} />
                            <label className="custom-control-label" for="26_30">26 - 30</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="31_40" checked={this.state.age_31_40} onClick={this.onChangeAge_31_40} />
                            <label className="custom-control-label" for="31_40">31 - 40</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="41_50" checked={this.state.age_41_50} onClick={this.onChangeAge_41_50} />
                            <label className="custom-control-label" for="41_50">41 - 50</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="51_60" checked={this.state.age_51_60} onClick={this.onChangeAge_51_60} />
                            <label className="custom-control-label" for="51_60">51 - 60</label>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="61_100" checked={this.state.age_61_100} onClick={this.onChangeAge_61_100} />
                            <label className="custom-control-label" for="61_100">61+</label>
                    </div>
                </div>
            </div>
        </div>
        }

        return (
            <div className="container-fluid">
                <h1 className="mt-4">Nuevo Email</h1>
                <br />
    
                <form onSubmit={this.exportHtml}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-md-3">
                        <label for="selectEmailType">Tipo de Email</label>
                            <select id="selectEmailType" className="form-control" value={this.state.email_type} onChange={this.onChangeEmailType}>
                                <option value="">--- Tipo de Email ---</option>
                                <option value={emailTypeWelcome}>Bienvenida</option>
                                <option value={emailTypeBirthDay}>Cumpleaños</option>
                                <option value={emailTypeTransaction}>Transacción</option>
                                <option value={emailTypeUpdateCategory}>Cambio de Categoría</option>
                                <option value={emailTypeInactiveUser}>Usuario Inactivo</option>
                                <option value={emailTypeCampaign}>Campaña</option>
                            </select>
                        </div>
                    </div>

                    {inactiveOptions}

                    {clientTypeSelect}

                    {campaignFiltersDates}
                    {campaignFiltersGender}
                    {campaignFiltersAges}

                    <div className="form-row align-items-center">
                    <div className="form-group col-md-12">
                            <label for="inputName">Asunto del Email</label>
                            <input className="form-control" type="text"  id="inputName" placeholder="Asunto" value={this.state.email_subject} onChange={this.onChangeEmailSubject} />
                            {
                                !this.state.is_valid_subject &&
                                <small className="form-text text-muted">
                                    <p className="text-danger">No se admiten campos variables en el asunto de campañas</p>
                                </small>
                            }
                        </div>
                    </div>

                    <div className="form-row align-items-center">
                        <div className="form-group col-md-12">
                            <EmailEditor ref={editor => this.editor = editor} onLoad={this.onReady} />
                        </div>
                    </div>

                    <div className="form-row align-items-center">
                        <div className="form-group col-md-4">
                            <input className="form-control" type="text" id="inputTestEmail" placeholder="mail@prueba.com" value={this.state.to_email} onChange={this.onChangeToEmail} />
                        </div>
                        <div className="form-group col-md-2">
                        <a onClick={this.testEmail} className="btn btn-sm btn-warning">Enviar Prueba</a>
                        </div>
                    </div>

                    <div className="form-group">
                        <button 
                            type="submit" 
                            className="btn btn-primary"
                            disabled={!this.state.is_valid_subject}
                        >
                            Confirmar
                        </button>
                    </div>
        
                </form>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToList()}>
                    <Alert onClose={() => this.goToList()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

    goToList() {
        const { match: { params } } = this.props;
        var url = `/stores/${params.storeId}`
        this.setState({ urlToRedirect: url + `/communications` })
    }

}

export default NewCommunication