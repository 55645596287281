import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import AccordionItem from '../AccordionItem';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Communications extends Component {

    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            communications: [],
            message: '',
            open: false,
        }

        this.onDeleted = this.onDeleted.bind(this)
    }

    onDeleted(deleteURL) {
        trackPromise(
            axios.post(deleteURL, null, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    this.setState({ open: true, message: 'Comunicación eliminada.' });
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/stores/${params.storeId}/communications`

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*', } })
                .then(res => { this.setState({ communications: res.data, open: false }); })
                .catch(function (error) { console.log(error); })
        );
    }

    createAccordionItem(communication) {
        const { match: { params } } = this.props;
        var title = ""

        switch (communication.email_type) {
            case 'WELCOME':
                title = "Bienvenida";
                break;
            case 'BIRTHDAY':
                title = "Cumpleaños";
                break;
            case 'UPDATE_CATEGORY':
                title = "Cambio de Categoría";
                break;
            case 'TRANSACTION':
                title = "Transacción";
                break;
            case 'INACTIVE_USER':
                title = "Usuarios Inactivos";
                break;
            case 'CAMPAIGN':
                title = "Campaña";
                break;
            default:
                title = "Tipo de correo desconocido";
        }

        var h2Id = "heading" + communication.id;
        var ariaControls = "collapse" + communication.id;
        var dataBsTarget = "#" + ariaControls;

        const encodedSubject = btoa(encodeURIComponent(communication.email_subject));

        return (
            <div className="accordion-item">
                <h2 className="accordion-header" id={h2Id}>
                    <div className="row align-items-center">
                        <div className="col-md-10">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={dataBsTarget} aria-expanded="false" aria-controls={ariaControls}>
                                <p>
                                    {title}
                                    <br />
                                    <br />
                                    <b>{communication.email_subject}</b>
                                </p>
                            </button>
                        </div>
                        <div className="col-md-1 d-flex justify-content-center">
                            <div className="col-md-1 d-flex justify-content-center mr-3">
                                <Link
                                    to={`/stores/${params.storeId}/communications/${communication.id}/edit`}
                                    className="btn btn-sm btn-success"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Editar"
                                >
                                    <span className="icon text-white-50">
                                        <i className="fas fa-edit"></i>
                                    </span>
                                </Link>
                            </div>
                            {communication.email_type === "CAMPAIGN" && (
                                <div className="col-md-1 d-flex justify-content-center mr-3">
                                    <Link
                                        to={`/stores/${params.storeId}/communications/${encodedSubject}/campaign_report`}
                                        className="btn btn-sm btn-info"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Ver reporte"
                                    >
                                        <span className="icon text-white-50">
                                            <i className="fas fa-chart-line"></i>
                                        </span>
                                    </Link>
                                </div>
                            )}
                            <div className="col-md-1 d-flex justify-content-center">
                                <a
                                    onClick={this.onDeleted.bind(this, `${this.context.hubURL}/stores/${params.storeId}/communications/${communication.id}/delete`)}
                                    data-tag={communication.id}
                                    className="btn btn-sm btn-danger"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Eliminar"
                                >
                                    <span className="icon text-white-50">
                                        <i className="fas fa-trash"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </h2>
                <div id={ariaControls} className="accordion-collapse collapse" aria-labelledby={h2Id} data-bs-parent="communications">
                    <div className="accordion-body">
                        <div dangerouslySetInnerHTML={{ __html: communication.email_content }} />
                    </div>
                </div>
            </div>


        );
    }

    render() {
        const { match: { params } } = this.props;

        let content, newButton

        if (this.state.communications == null || this.state.communications.length == 0) {
            content = "No hay comunicaciones creadas."
        } else {
            content = <div className="container">
                <div class="accordion" id="communications">
                    {this.state.communications.map((communication) => (
                        this.createAccordionItem(communication)
                    ))}
                </div>
            </div>
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col text-left my-auto">
                        <h3 className="h3 mb-4 text-gray-800">Comunicaciones</h3>
                    </div>
                    <div className="col text-right my-auto">
                        <Link to={`/stores/${params.storeId}/communications/new`} className="btn btn-success"><span className="icon text-white-50"><i className="fas fa-plus"></i></span></Link>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.componentDidMount()}>
                    <Alert onClose={() => this.componentDidMount()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Communications