import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addMonths, format } from 'date-fns';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const rewardTypePoints = "POINTS"
const rewardTypeCash = "CASH"
const rewardTypeOther = "OTHER"
const rewardTypeStamps = "STAMPS"

const actionTypeNewUser = "NEW_USER"
const actionTypeTransactionCount = "TRANSACTION_COUNT"
const actionTypeTransactionAmount = "TRANSACTION_AMOUNT"
const actionTypeStamps = "STAMPS"

class NewReward extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            action_type: '',
            count: '',
            reward_type: '',
            reward_count: '',
            open: false,
            someChanges: false,
            urlToRedirect: null,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeActionType = this.onChangeActionType.bind(this)
        this.onChangeCount = this.onChangeCount.bind(this)
        this.onChangeRewardType = this.onChangeRewardType.bind(this)
        this.onChangeRewardCount = this.onChangeRewardCount.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.goToList = this.goToList.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if (params.rewardId != null) {
            trackPromise(
                axios.get(`${this.context.hubURL}/stores/${params.storeId}/rewards/${params.rewardId}`, {
                    headers: {
                    'Access-Control-Allow-Origin': '*',
                    }
                })
                    .then(res => {
                        this.setState({ 
                            action_type: res.data["action_type"], 
                            count: res.data["count"], 
                            reward_type: res.data["reward_type"], 
                            reward_count: res.data["reward_count"], 
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            );
        }
    }
    

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        }

        const obj = {
            action_type: this.state.action_type,
            count: this.state.count,
            reward_type: this.state.reward_type,
            reward_count: this.state.reward_count,
        };

        var url = `${this.context.hubURL}/stores/${params.storeId}/rewards`;
        var message = 'Premio agregado!';
        if (params.bannerId != null) {
            url = url + `/${params.bannerId}`
            message = 'Premio editado!';
        }

        trackPromise(
            axios.post(url, obj, {
                headers: { 'Access-Control-Allow-Origin': '*', } })
                .then((res) => {
                    this.setState({ open: true, message: message })
                }).catch((error) => {
                    console.log(error)
                })
        );
    }

    onChangeActionType(e) {
        this.setState({ action_type: e.target.value, someChanges:true })
    }

    onChangeCount(e) {
        this.setState({ count: e.target.value, someChanges:true })
    }

    onChangeRewardType(e) {
        this.setState({ reward_type: e.target.value, someChanges: true })
    }

    onChangeRewardCount(e) {
        this.setState({ reward_count: e.target.value, someChanges: true })
    }

    setOpen(value) {
        this.setState({ open: value })
    }

    render() {
        if (this.state.urlToRedirect !== null) {
            return <Redirect to={this.state.urlToRedirect} />
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Nuevo Premio</h1>
                <br />
    
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="selectActionType">Acción disparadora</label>
                                    <select id="selectActionType" className="form-control" value={this.state.action_type} onChange={this.onChangeActionType}>
                                        <option value="">--- Tipo de Acción ---</option>
                                        <option value={actionTypeNewUser}>Nuevo Usuario</option>
                                        <option value={actionTypeTransactionCount}>Cantidad Transacciones</option>
                                        <option value={actionTypeTransactionAmount}>Monto Transacción</option>
                                        <option value={actionTypeStamps}>Estampas</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="inputCount">Cantidad esperada</label>
                                    <input className="form-control" type="text" 
                                    id="inputCount" placeholder="Cantidad esperada" 
                                    value={this.state.count} onChange={this.onChangeCount} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputRewardType">Tipo de Premio</label>
                                    <select id="inputRewardType" className="form-control" value={this.state.reward_type} onChange={this.onChangeRewardType}>
                                        <option value="">--- Tipo de Premio ---</option>
                                        <option value={rewardTypeCash}>Pesos</option>
                                        <option value={rewardTypePoints}>Puntos</option>
                                        <option value={rewardTypeOther}>Otro</option>
                                        <option value={rewardTypeStamps}>Estampas</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="inputRewardCount">Cantidad Premio</label>
                                    <input className="form-control" type="text" 
                                    id="inputRewardCount" placeholder="Premio" 
                                    value={this.state.reward_count} onChange={this.onChangeRewardCount} />
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.goToList()}>
                    <Alert onClose={() => this.goToList()} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        )
    }

    goToList() {
        const { match: { params } } = this.props;
        this.setState({ urlToRedirect: `/stores/${params.storeId}/rewards` })
    }

}

export default NewReward