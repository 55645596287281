import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'; 
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Tooltip, Legend } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import SessionContext from '../../SessionContext';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { trackPromise } from 'react-promise-tracker';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Tooltip, Legend);

class CampaignReport extends Component {
    static contextType = SessionContext;

    constructor() {
        super();
        this.state = {
            reportData: {},
            reportDetailsData: [],
            loading: true,
            error: null,
            open: false,
            message: '',
            storeID: 0,
            subject: "",
            showModal: false,
            emailsToResend: [],
            resendSuccess: false,
        };
        this.handleDownloadReport = this.handleDownloadReport.bind(this);
        this.handleResendEmails = this.handleResendEmails.bind(this);
        // this.handleConfirmResendEmails = this.handleConfirmResendEmails.bind(this);
        // this.handleCloseModal = this.handleCloseModal.bind(this);
        this.lineChartRef = React.createRef();
        this.doughnutChartRef = React.createRef();
        this.lineChartInstance = null;
        this.doughnutChartInstance = null;
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const url = `${this.context.hubURL}/stores/${params.storeId}/mailing_report?subject=${decodeURIComponent(atob(params.email_subject))}`;
        // const url = `http://localhost:8080/stores/${params.storeId}/mailing_report?subject=${decodeURIComponent(atob(params.email_subject))}`;
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*' } })
                .then(res => {
                    this.setState({ 
                        reportData: res.data.total,
                        reportDetailsData: res.data.details,
                        loading: false,
                        open: false, 
                        storeID: params.storeId,
                        subject: params.email_subject, 
                    }, this.createCharts);
                })
                .catch(error => {
                    this.setState({ reportData: null, loading: false, storeID: params.storeId });
                })
        );
    }

    componentWillUnmount() {
        // Destruimos los gráficos cuando el componente se desmonta
        if (this.lineChartInstance) {
            this.lineChartInstance.destroy();
        }
        if (this.doughnutChartInstance) {
            this.doughnutChartInstance.destroy();
        }
    }

    createCharts = () => {
        const { reportData, reportDetailsData } = this.state;

        // Verificamos si hay gráficos ya renderizados, si es así los destruimos
        if (this.lineChartInstance) {
            this.lineChartInstance.destroy();
        }
        if (this.doughnutChartInstance) {
            this.doughnutChartInstance.destroy();
        }

        // Datos para el gráfico de líneas
        const lineData = {
            labels: reportDetailsData.map(item => item.date),
            datasets: [
                {
                    label: 'Opens',
                    data: reportDetailsData.map(item => item.opens),
                    borderColor: 'rgba(75,192,192,1)',
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    fill: true,
                    tension: 0.4,
                },
                {
                    label: 'Clicks',
                    data: reportDetailsData.map(item => item.clicks),
                    borderColor: 'rgba(255,165,0,1)',
                    backgroundColor: 'rgba(255,165,0,0.2)',
                    fill: true,
                    tension: 0.4,
                },
            ],
        };

        this.lineChartInstance = new Chart(this.lineChartRef.current, {
            type: 'line',
            data: lineData,
            options: {
                animation: true,
                scales: {
                    x: {
                        beginAtZero: true,
                        display: reportDetailsData.length > 10 ? false : true,
                        ticks: {
                            autoSkip: false,
                        },
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        // Datos para el gráfico de doughnut        
        const uniqueOpens = reportData.unique_opens
        const totalSent = reportData.sent_count
        const totalBounced = reportData.rejected_hard_count + reportData.rejected_soft_count
        const totalNotOpened = reportData.total_not_open

        const doughnutData = {
            labels: ['Abiertos', 'No Abiertos', 'Rebotados'],
            datasets: [
                {
                    data: [uniqueOpens, totalNotOpened, totalBounced],
                    backgroundColor: ['#4CAF50', '#FFC107', '#F44336'],
                },
            ],
        };

        this.doughnutChartInstance = new Chart(this.doughnutChartRef.current, {
            type: 'doughnut',
            data: doughnutData,
        });
    };

    handleCloseSnackbar = () => {
        this.setState({ open: false });
    };

    handleResendEmails = (e) => {
        e.preventDefault();
        // Abrimos el modal y pasamos los emails que no han abierto la campaña
        const emails = this.state.reportDetailsData
            .filter(report => report.opens === 0 && report.status === "Sent")
            .map(report => report.client_email);
        this.setState({ showModal: true, emailsToResend: emails });
    };

    handleConfirmResendEmails = () => {
        // const url = `http://localhost:8080/stores/${this.state.storeID}/communications/resend/not_open?subject=${decodeURIComponent(atob(this.state.subject))}`
        const url = `${this.context.hubURL}/stores/${this.state.storeID}/communications/resend/not_open?subject=${decodeURIComponent(atob(this.state.subject))}`       
        const dataObj = {
            emails: this.state.emailsToResend
        };
        trackPromise(
            axios.post(url, dataObj, { headers: { 'Access-Control-Allow-Origin': '*' } })
            .then(response => {
                this.setState({ showModal: false, resendSuccess: true });
            })
            .catch(error => {
                console.error('Error al reenviar los emails:', error);
            })
        );
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleDownloadReport = (reportType) => {
        // const url = `http://localhost:8080/stores/${this.state.storeID}/download_report?subject=${decodeURIComponent(atob(this.state.subject))}&report_type=${reportType}`;
        const url = `${this.context.hubURL}/stores/${this.state.storeID}/download_report?subject=${decodeURIComponent(atob(this.state.subject))}&report_type=${reportType}`;
        trackPromise(
            axios.get(url, {
                responseType: 'blob', // Para recibir un archivo binario
            })
            .then(response => {
                console.log(url);
                const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = downloadURL;
                
                let name;
                if (reportType === "OPEN") {
                    name = "emails-abiertos";
                } else if (reportType === "NOT_OPEN") {
                    name = "emails-no-abiertos";
                } else if (reportType === "REJECTED") {
                    name = "emails-rebotados";
                }
                
                a.download = `${name}-reporte.xlsx`;  // Cambiar la extensión a '.xlsx'
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch(error => {
                console.error('Error descargando el reporte:', error);
            })
        );
    };
    
    render() {
        const { reportData, loading, error, open, message, storeID } = this.state;

        if (loading) return <div>Cargando...</div>;
        if (error) return <div>Error: {error}</div>;
        if (!reportData) return (
            <div className="container">
                <h3 className="h3 mb-4 text-gray-800">No se encontró el reporte.</h3>
                <Link to={`/stores/${storeID}/communications`} className="btn btn-primary">
                    Volver
                </Link>
            </div>
        );

        // Resumen de la campaña
        const totalOpens = reportData.total_opens
        const uniqueOpens = reportData.unique_opens
        const totalSent = reportData.sent_count
        const totalBounced = reportData.rejected_hard_count + reportData.rejected_soft_count
        const totalSubscriptors = reportData.total_subscriptors      
        const totalNotOpened = reportData.total_not_open
        const totalEmails = totalSent + totalBounced;
        const totalClicks = reportData.total_clicks
        const clickThroughRate = (totalClicks / totalSent * 100).toFixed(1)
        let lastOpen = ""
        let lastClick = ""
        if (reportData.last_open_date != "") {
            lastOpen = new Date(reportData.last_open_date).toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })
        }
        if (reportData.last_click_date != "") {
            lastClick = new Date(reportData.last_click_date).toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })
        }
        

        return (
            <div className="container mt-3">
                {/* Tasa de Entrega */}
                <div className="card mb-4">
                    <div className="pl-3 pt-3 pr-3">
                        <h2 className="card-title">Tasa de entrega</h2>
                        <hr/>
                    </div>
                    <div className="card-body d-flex justify-content-between align-items-center">
                        <div className="col-6">
                            <div className="d-flex align-items-center" style={{ width: '300px', height: '300px' }}>
                                <canvas ref={this.doughnutChartRef}></canvas>
                                <ul className="list-unstyled text-center mt-3">
                                    <li className="d-flex align-items-center col-2 mx-2 mb-1">
                                        <div className="bg-success text-white p-3 rounded text-center" style={{minWidth:"110px"}}>
                                            <strong className="h5">
                                                {Math.min(((uniqueOpens / totalEmails) * 100).toFixed(1), 100)}%
                                            </strong>                                                                        
                                        </div>
                                        <div className="ml-2 text-center">
                                            <span className="h6 d-flex align-items-center">
                                                <strong className="mr-2">{uniqueOpens}</strong> Abiertos
                                            </span>
                                        </div>                                        
                                    </li>
                                    <li className="d-flex align-items-center col-2 mx-2 mb-1">
                                        <div className="bg-warning text-white p-3 rounded text-center" style={{minWidth:"110px"}}>
                                            <strong className="h5">
                                                {Math.min(((totalNotOpened / totalEmails) * 100).toFixed(1), 100)}%
                                            </strong>
                                        </div>
                                        <div className="ml-2 text-center">
                                            <span className="h6 d-flex align-items-center">
                                                <strong className="mr-2">{totalNotOpened}</strong> No abiertos
                                            </span>
                                        </div>                                        
                                    </li>
                                    <li className="d-flex align-items-center col-2 mx-2">
                                        <div className="bg-danger text-white p-3 rounded text-center" style={{ minWidth: "110px" }}>
                                            <strong className="h5">
                                                {Math.min(((totalBounced / totalEmails) * 100).toFixed(1), 100)}%
                                            </strong>
                                        </div>
                                        <div className="ml-2 text-center">
                                            <span className="h6 d-flex align-items-center">
                                                <strong className="mr-2">{totalBounced}</strong> Rebotados
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>                           
                            <div className="bg-secondary text-white p-3 rounded text-center">
                                <strong>TASA DE CLICKS: {clickThroughRate}%</strong>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="">
                                <h3 className="card-title">Resumen de la Campaña</h3>
                                <ul className="list-unstyled card bg-light p-2">
                                    <li className="d-flex justify-content-between mb-2">Total de Suscriptores: <strong>{totalSubscriptors}</strong></li>
                                    <li className="d-flex justify-content-between mb-2">Emails Entregados: <strong>{totalSent}</strong></li>
                                    <li className="d-flex justify-content-between mb-2">Aperturas Únicas: <strong>{uniqueOpens}</strong></li>
                                    {/* <li>Cantidad de Reenvíos: <strong>0</strong></li> */}
                                    <li className="d-flex justify-content-between mb-2">Total de Aperturas: <strong>{totalOpens}</strong></li>
                                    <li className="d-flex justify-content-between mb-2">Clicks Totales: <strong>{totalClicks}</strong></li>
                                    {lastOpen != "" &&
                                        <li className="d-flex justify-content-between mb-2">Última Apertura: <strong>{lastOpen}</strong></li>
                                    }
                                    {/* <li>Clicks Únicos: <strong>2</strong></li> */}
                                    {lastClick != "" && 
                                        <li className="d-flex justify-content-between">Último Click: <strong>{lastClick}</strong></li>
                                    }
                                    {/* <li>Cantidad de Remociones: <strong>{totalBounced}</strong></li> */}
                                </ul>                            
                            </div>
                        </div>
                    </div>
                </div>
                {/* Sección descarga de reporte */}
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="card-title">Detalle de actividad de tasa de entrega</h2>
                        <p>En el siguiente listado podrá visualizar la actividad de cada suscriptor.</p>                                
                        <ul className="list-unstyled card bg-light p-2">                            
                            {/* Emails Abiertos */}
                            <li className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="badge bg-success rounded-circle mr-2 p-3">✓</span>
                                    <strong>Emails Abiertos: {uniqueOpens}</strong><span className="ml-2">|  {Math.min(((uniqueOpens / totalEmails) * 100).toFixed(1), 100)}%</span>
                                </div>                                
                                {
                                    uniqueOpens > 0 &&
                                    <button onClick={() => this.handleDownloadReport('OPEN')} className="btn btn-link">Descargar Reporte</button>
                                }
                            </li>
                            <hr/>
                            {/* Emails Sin Abrir */}
                            <li className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="badge bg-secondary rounded-circle mr-2 p-3">—</span>
                                    <strong>Emails Sin Abrir: {totalNotOpened}  </strong><span className="ml-2">|  {Math.min(((totalNotOpened / totalEmails) * 100).toFixed(1), 100)}%</span>
                                    {
                                        totalNotOpened > 0 &&
                                        <button onClick={(e) => this.handleResendEmails(e)} className="btn btn-outline-warning ml-3">Reenviar emails</button>
                                    }
                                </div> 
                                {
                                    totalNotOpened > 0 &&
                                    <button onClick={() => this.handleDownloadReport('NOT_OPEN')} className="btn btn-link">Descargar Reporte</button>                                
                                }                               
                            </li>
                            <hr/>
                            {/* Emails Rebotados */}
                            <li className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="badge bg-danger rounded-circle mr-2 p-3">×</span>
                                    <strong>Emails Rebotados: {totalBounced}  </strong><span className="ml-2">|  {Math.min(((totalBounced / totalEmails) * 100).toFixed(1), 100)}%</span>
                                </div>
                                {
                                    totalBounced > 0 &&
                                    <button onClick={() => this.handleDownloadReport('REJECTED')} className="btn btn-link">Descargar Reporte</button>
                                }                                
                            </li>
                        </ul>                        
                    </div>
                </div>
                {/* Aperturas y Clicks */}
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="card-title">Aperturas y clicks por día</h2>
                        <p>El siguiente Reporte detalla la cantidad de Aperturas y Clicks que recibió la Campaña enviada.</p>
                        <canvas ref={this.lineChartRef} height="70%"></canvas>
                    </div>
                </div>
                {/* Modal de confirmación */}
                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar reenvío</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se reenviarán los correos a los siguientes destinatarios:</p>
                        <ul>
                            {this.state.emailsToResend.map((email, index) => (
                                <li key={index}>{email}</li>
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={this.handleConfirmResendEmails}>
                            Confirmar reenvío
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Mostrar mensaje de éxito */}
                {this.state.resendSuccess && (
                    <Snackbar open={this.state.resendSuccess} autoHideDuration={6000} onClose={() => this.setState({ resendSuccess: false })}>
                        <MuiAlert elevation={6} variant="filled" severity="success">
                            Campaña reenviada con éxito
                        </MuiAlert>
                    </Snackbar>
                )}                   
            </div>
        );
    }
}

export default CampaignReport;
