import React, { Component, useRef } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import { NavLink, Redirect } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { EmailEditor } from 'react-email-editor';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewBranch extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            id: 0,
            name: '',
            file: '',
            base64: '',
            url: '',
            phone: '',
            whatsapp: '',
            address: '',
            latitude: 0,
            longitude: 0,
            email: '',
            facebook: '',
            instagram: '',
            open: false,
            someChanges: false,
            openBranches: false,
            message: '',
            storeID: null,
            website: null,
            comments: null,
            sunday_open: false,
            sunday_from: null,
            sunday_to: null,
            monday_open: false,
            monday_from: null,
            monday_to: null,
            tueday_open: false,
            tuesday_from: null,
            tuesday_to: null,
            wednesday_open: false,
            wednesday_from: null,
            wednesday_to: null,
            thursday_open: false,
            thursday_from: null,
            thursday_to: null,
            friday_open: false,
            friday_from: null,
            friday_to: null,
            saturday_open: false,
            saturday_from: null,
            saturday_to: null,
            category: null,
            categories: [],
                center: {
                  lat: 10.99835602,
                  lng: 77.01502627
                },
                zoom: 11,
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangePhone = this.onChangePhone.bind(this)
        this.onChangeAddress = this.onChangeAddress.bind(this)
        this.onChangeLatitude = this.onChangeLatitude.bind(this)
        this.onChangeLongitude = this.onChangeLongitude.bind(this)
        this.onChangeWhatsApp = this.onChangeWhatsApp.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangeFacebook = this.onChangeFacebook.bind(this)
        this.onChangeInstagram = this.onChangeInstagram.bind(this)
        this.onChangeWebsite = this.onChangeWebsite.bind(this)
        this.onChangeComments = this.onChangeComments.bind(this)
        this.onStoreCategoryChanged = this.onStoreCategoryChanged.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)       
    }

    componentDidMount() {
        window.initAutocomplete();
        const { match: { params } } = this.props;
        var isBranch = false;
        let url = `${this.context.hubURL}/stores_categories`
        
        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*',} })
                .then(res => { 
                    this.getBranch(res.data);
                })
                .catch(function (error) { console.log(error); })
        );
    }

    getBranch(categories) {
        const { match: { params } } = this.props;

        if (params.storeId !== undefined) {
            if (params.branchId !== undefined) {
                trackPromise(
                    axios.get(`${this.context.hubURL}/stores/${params.storeId}/branches/${params.branchId}`, {
                        headers: { 'Access-Control-Allow-Origin': '*' }
                    })
                    .then(res => {
                        this.setState({ 
                            id: res.data.branch.id,
                            name: res.data.branch.name,
                            url: res.data.branch.logo_url,
                            phone: res.data.branch.phone,
                            whatsapp: res.data.branch.whatsapp,
                            address: res.data.branch.address,
                            latitude: res.data.branch.latitude,
                            longitude: res.data.branch.longitude,
                            email: res.data.branch.email,
                            facebook: res.data.branch.facebook,
                            instagram: res.data.branch.instagram, 
                            website: res.data.branch.website, 
                            comments: res.data.branch.comments, 
                            category: res.data.branch.category,
                            storeID: params.storeId,
                            categories: categories,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                );
            } else {
                this.setState({ 
                    storeID: params.storeId,
                });
            }
        } 
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        if (this.state.someChanges === false) {
            this.props.history.go(-1);
            return
        } else {
            var image = ''
            if (this.state.base64 !== '') {
                image = this.state.base64;
            } else {
                image = this.state.url;
            }

            const object = {
                name: this.state.name,
                logo_url: image,
                phone: this.state.phone,
                address: this.state.address,
                longitude: this.state.longitude.toString(),
                latitude: this.state.latitude.toString(),
                whatsapp: this.state.whatsapp,
                email: this.state.email,
                facebook: this.state.facebook,
                instagram: this.state.instagram,
                website: this.state.website,
                comments: this.state.comments,
                category: this.state.category,
                storeId: params.storeId
            };

            if (this.state.id !== 0) {
                alert("ID");
                trackPromise(
                    axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches/${this.state.id}`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                    .then((res) => {
                        this.setState({ open: true, message: 'Sucursal actualizada!' })
                    }).catch((error) => {
                        console.log(error)
                    })
                );    
            } else {
                trackPromise(
                    axios.post(`${this.context.hubURL}/stores/${params.storeId}/branches`, object, { headers: { 'Access-Control-Allow-Origin': '*' } })
                    .then((res) => {
                        this.setState({ open: true, message: 'Sucursal agregada!' })
                    }).catch((error) => {
                        console.log(error)
                    })
                );
            }
        }
    }

    onChangeName(e) {
        this.setState({ name: e.target.value, someChanges: true })
    }

    onChangePhone(e) {
        this.setState({ phone: e.target.value, someChanges: true })
    }

    onChangeAddress(e) {
        this.setState({ address: e.target.value, someChanges: true })
    }

    onChangeWhatsApp(e) {
        this.setState({ whatsapp: e.target.value, someChanges: true })
    }

    onChangeEmail(e) {
        this.setState({ email: e.target.value, someChanges: true })
    }

    onChangeFacebook(e) {
        this.setState({ facebook: e.target.value, someChanges: true })
    }

    onChangeLatitude(e) {
        this.setState({ latitude: e.target.value, someChanges: true })
    }

    onChangeLongitude(e) {
        this.setState({ longitude: e.target.value, someChanges: true })
    }

    onChangeInstagram(e) {
        this.setState({ instagram: e.target.value, someChanges: true })
    }

    onChangeWebsite(e) {
        this.setState({ website: e.target.value, someChanges: true })
    }

    onChangeComments(e) {
        this.setState({ comments: e.target.value, someChanges: true })
    }

    onStoreCategoryChanged(e) { 
        if (e.target.value != "-1") {
            this.setState({category: e.target.value, someChanges: true})
        }
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        if (this.state.openBranches) {
            return <Redirect to={`/stores/${this.state.storeID}/branches`} />
        }

        let previewDiv, defined_class;
        if (this.state.file) {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.file} alt='' /></div>
            defined_class = "col-md-9"
        } else if (this.state.url !== '') {
            previewDiv = <div className="col-md-3 preview"><img height="80" width="80" src={this.state.url} alt='' /></div>
            defined_class = "col-md-9"
        } else {
            defined_class = "col-md-12"
        }

        let categoriesSelect;
        if (this.state.categories === null) {
            categoriesSelect = ""
        } else {
            categoriesSelect = <div className="form-group col-md-4">
                <label for="selectCategory">Rubro</label>
                <select id="selectCategory" className="form-control" value={this.state.category} onChange={this.onStoreCategoryChanged}>
                    <option value="-1">Seleccioná una opción</option>
                    { this.state.categories.map((category) => (
                        <option value={category.id}>{category.name}</option>
                    )) 
                    }
                </select>
            </div>
        }

        return (
            <div className="container-fluid">
                <h1 className="mt-4">Nueva Sucursal</h1>
                <br />
    
                <form onSubmit={this.onFormSubmitted}>
                    <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                            <label for="inputName">Nombre Sucursal</label>
                            <input className="form-control" type="text" 
                            id="inputName" placeholder="Fantasia" 
                            value={this.state.name} onChange={this.onChangeName} />
                        </div>

                        <div className="form-group col-md-6">
                            <div className="row align-items-center">
                                {previewDiv}
                                <div className={defined_class}>
                                    <label htmlFor="inputFile">Logo (Max. 10MB)</label>
                                    <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        {categoriesSelect}
                        <div className="form-group col-md-4">
                            <label for="inputPhone">Teléfono</label>
                            <input className="form-control" type="phone" 
                            id="inputPhone" placeholder="(XX) XXXX XXXX" 
                            value={this.state.phone} onChange={this.onChangePhone} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputWhatsApp">WhatsApp</label>
                            <input className="form-control" type="phone" 
                            id="inputWhatsApp" placeholder="(XX) XXXX XXXX" 
                            value={this.state.whatsapp} onChange={this.onChangeWhatsApp} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label for="inputEmail">E-mail</label>
                            <input className="form-control" type="email" 
                            id="inputEmail" placeholder="casilla@correo.com" 
                            value={this.state.email} onChange={this.onChangeEmail} />
                        </div>
                        <div className="form-group col-md-6">
                            <label for="inputWebsite">Sitio Web</label>
                            <input className="form-control" type="url" 
                            id="inputWebsite" placeholder="www.sitio.com.ar" 
                            value={this.state.website} onChange={this.onChangeWebsite} />
                        </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6">
                            <label for="inputInstagram">Instagram</label>
                            <input className="form-control" type="url" 
                            id="inputInstagram" placeholder="http://instagram.com/usuario" 
                            value={this.state.instagram} onChange={this.onChangeInstagram} />
                        </div>
                        <div className="form-group col-md-6">
                            <label for="inputFacebook">Facebook</label>
                            <input className="form-control" type="url" 
                            id="inputFacebook" placeholder="http://facebook.com/usuario" 
                            value={this.state.facebook} onChange={this.onChangeFacebook} />
                        </div>
                    </div>

                    <div className="form-row" style={{ height: '300px'}}>
                        <div className="form-group col-md-6">
                            <label for="inputAddress">Dirección</label>
                            <input className="form-control" type="text" 
                            id="googleMapAddress" placeholder="Dirección" 
                            value={this.state.address} onChange={this.onChangeAddress} />

                            <label for="inputLatitude">Latitud</label>
                            <input className="form-control" type="text" 
                            id="googleMapAddressLatitude" placeholder="Latitud" 
                            value={this.state.latitude} onChange={this.onChangeLatitude} readOnly/>

                            <label for="inputLongitude">Longitud</label>
                            <input className="form-control" type="text" 
                            id="googleMapAddressLongitude" placeholder="Longitud" 
                            value={this.state.longitude} onChange={this.onChangeLongitude} readOnly/>
                        </div>
                        <div className="form-group col-md-6">
                            <div id="map" />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputLines">Comentarios</label>
                            <textarea class="form-control" id="inputLines" rows="4" cols="120" onChange={this.onChangeComments} defaultValue={this.state.comments} />
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Confirmar</button>
                    </div>
                </form>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({openBranches: true})}>
                    <Alert onClose={() => this.setState({openBranches: true})} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}

export default NewBranch