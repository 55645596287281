import React, { Component } from 'react';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SessionContext from '../../SessionContext';
import { trackPromise } from 'react-promise-tracker';
import YesNoSwitch from '../YesNoSwitch';
import CustomFieldsForm from './CustomFieldsForm';
import CustomFieldsUtils from '../../utils/customFieldsUtils';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class StoreNewClient extends Component {
    static contextType = SessionContext

    constructor() {
        super()
        this.state = {
            file: '',
            base64: '',
            url: '',
            request_address: 0,
            request_facebook: 0,
            request_phone: 0,
            pathname: '',
            custom_fields: [],
            custom_field_name: '',
            custom_field_type: '',
            custom_multiple_choice_field: null,
            disable_add_custom_field: true,
            invalid_multiple_choice: false,
            reached_max_custom_fields: false,
            terms_and_conditions: '',
            terms_and_conditions_url: '',
            open: false,
            success: false
        }

        this.onFormSubmitted = this.onFormSubmitted.bind(this)
        this.onChangePathName = this.onChangePathName.bind(this)
        this.onChangeRequestAddress = this.onChangeRequestAddress.bind(this)
        this.onChangeRequestPhone = this.onChangeRequestPhone.bind(this)
        this.onChangeRequestFacebook = this.onChangeRequestFacebook.bind(this)
        this.onChangeTermsAndConditions = this.onChangeTermsAndConditions.bind(this)
        this.onChangeTermsAndConditionsURL = this.onChangeTermsAndConditionsURL.bind(this)
        this.onChangeCustomFieldName = this.onChangeCustomFieldName.bind(this)
        this.onChangeCustomFieldType = this.onChangeCustomFieldType.bind(this)
        this.onChangeCustomMultipleChoiceField = this.onChangeCustomMultipleChoiceField.bind(this)
        this.setOpen = this.setOpen.bind(this)
        this.setSuccess = this.setSuccess.bind(this)
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        let url = `${this.context.hubURL}/stores/${params.storeId}/newclientpage`;
        // let url = `http://localhost:8080/stores/${params.storeId}/newclientpage`;

        trackPromise(
            axios.get(url, { headers: { 'Access-Control-Allow-Origin': '*', }})
                .then(res => {
                    this.setState({ 
                        pathname: res.data["pathname"], 
                        request_address: res.data["request_address"], 
                        request_facebook: res.data["request_facebook"], 
                        request_phone: res.data["request_phone"], 
                        url: res.data["background_url"], 
                        custom_fields: res.data["custom_fields"] !== null ? res.data["custom_fields"] : [],
                        terms_and_conditions: res.data["terms_and_conditions"], 
                        terms_and_conditions_url: res.data["terms_and_conditions_url"],
                    });
                })
                .catch(function (error) { console.log(error); })
        );
    }

    onFormSubmitted(e) {
        const { match: { params } } = this.props;
        
        e.preventDefault()

        var image = ''
        if (this.state.base64 !== '') {
            image = this.state.base64;
        } else {
            image = this.state.url;
        }

        const obj = {
            pathname: this.state.pathname,
            background_url: image,
            request_address: this.state.request_address,
            request_facebook: this.state.request_facebook,
            request_phone: this.state.request_phone,
            terms_and_conditions: this.state.terms_and_conditions,
            terms_and_conditions_url: this.state.terms_and_conditions_url,
            custom_fields: this.state.custom_fields
        };
        let url = `${this.context.hubURL}/stores/${params.storeId}/newclientpage`;
        // let url = `http://localhost:8080/stores/${params.storeId}/newclientpage`;

        trackPromise(
            axios.post(url, obj, { headers: { 'Access-Control-Allow-Origin': '*', } })
                .then((res) => { this.setOpen(true) })
                .catch((error) => { console.log(error) })
        );
    }
    
    onChangePathName(e) {
        this.setState({ pathname: e.target.value })
    }

    onChangeTermsAndConditions(e) {
        this.setState({ terms_and_conditions: e.target.value })
    }

    onChangeTermsAndConditionsURL(e) {
        this.setState({ terms_and_conditions_url: e.target.value })
    }

    onChangeRequestFacebook(e) {
        let v = e ? 1 : 0
        this.setState({ request_facebook: v })
    }

    onChangeRequestPhone(e) {
        let v = e ? 1 : 0
        this.setState({ request_phone: v })
    }

    onChangeRequestAddress(e) {
        let v = e ? 1 : 0
        this.setState({ request_address: v })
    }

    onChangeCustomFieldName(e) {
        this.setState({ custom_field_name: e.target.value })
    }

    onChangeCustomFieldType(e) {
        e.preventDefault()
        this.setState({ 
            custom_field_type: e.target.value,
            disable_add_custom_field: false,
        })
    }

    onChangeCustomMultipleChoiceField(e) {
        this.setState({ custom_multiple_choice_field: e.target.value })
    }

    addCustomField = (e) => {
        e.preventDefault()
        const { custom_field_name, custom_field_type, custom_multiple_choice_field } = this.state;
        const {ValidateMultipleChoiceOrDropdownField} = new CustomFieldsUtils()
        if (!ValidateMultipleChoiceOrDropdownField(custom_multiple_choice_field)) {
            this.setState({ invalid_multiple_choice: true })
            return
        }
    
        const newCustomField = {
          name: custom_field_name,
          type: custom_field_type,
          multiple_choice_field: custom_multiple_choice_field
        };
  
        this.setState((prevState) => ({
          custom_fields: [...prevState.custom_fields, newCustomField],
        }))

        this.clearFields();
    }

    deleteCustomField = (e, index) => {
        e.preventDefault()
        const updatedFields = [...this.state.custom_fields];
        updatedFields.splice(index, 1);
        this.setState({
          custom_fields: updatedFields,
        });
      };
    
    clearFields = () => {
        this.setState({
          custom_field_name: '',
          custom_field_type: '',
          custom_multiple_choice_field: null,
          disable_add_custom_field: true,
        });
    };
    
    setOpen(value) {
        this.setState({ open: value })
    }

    setSuccess(value) {
        this.setState({ success: value })
    }

    uploadSingleFile(e) {
        let file = e.target.files[0];

        var size = file.size / 1024 / 1024; // in MB
        if (size > 10) {
            alert('Archivo demasiado grande.');
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: URL.createObjectURL(file),
                    base64: reader.result, 
                    someChanges: true
                });
            }
        }
    }

    render() {
        // if (this.state.success) {
        //     return <Redirect to="/stores" />
        // }

        let banner;
        if (this.state.file) {
            banner = <div className="row">
                <img id="imgHeader" style={{width: "100%", height: "auto"}} src={this.state.file} />
            </div>
        } else if (this.state.url !== '') {
            banner = <div className="row">
                <img id="imgHeader" style={{width: "100%", height: "auto"}} src={this.state.url} />
            </div>
        }

        return (
            <div className="container-fluid"> 
                <h1 className="mt-4">Configuración Sitio Alta de Clientes</h1>
                <br />
                {banner}
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.onFormSubmitted}>
                            <div className="form-row align-items-center">
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputFile">Banner (Max. 10MB)</label>
                                    <input type="file" id="inputFile" className="form-control" onChange={this.uploadSingleFile} />
                                </div>
                            </div>

                            <div className="form-group col-md-12">
                                <label for="inputPortalURL">Nombre Web</label>
                                <input className="form-control" type="text" id="inputPathname" 
                                placeholder="nombreempresa"
                                value={this.state.pathname} onChange={this.onChangePathName} />
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-3 text-center">
                                    <h6>Solicitar Dirección</h6>
                                    <YesNoSwitch key="request_address" onChange={this.onChangeRequestAddress} on={this.state.request_address === 1} />
                                </div>
                                <div className="form-group col-md-3 text-center">
                                    <h6>Solicitar Teléfono</h6>
                                    <YesNoSwitch key="request_phone" onChange={this.onChangeRequestPhone} on={this.state.request_phone === 1} />
                                </div>
                                <div className="form-group col-md-3 text-center">
                                    <h6>Solicitar Perfil Facebook</h6>
                                    <YesNoSwitch key="request_facebook" onChange={this.onChangeRequestFacebook} on={this.state.request_facebook === 1} />
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <CustomFieldsForm
                                    custom_field_name={this.state.custom_field_name}
                                    custom_field_type={this.state.custom_field_type}
                                    custom_multiple_choice_field={this.state.custom_multiple_choice_field}
                                    custom_fields={this.state.custom_fields}
                                    onChangeCustomFieldName={this.onChangeCustomFieldName}
                                    onChangeCustomFieldType={this.onChangeCustomFieldType}
                                    onChangeCustomMultipleChoiceField={this.onChangeCustomMultipleChoiceField}
                                    addCustomField={this.addCustomField}
                                    deleteCustomField={this.deleteCustomField}
                                    disableAddCustomField={this.state.disable_add_custom_field}
                                    invalidMultipleChoice={this.state.invalid_multiple_choice}
                                    reachedMaxCustomFields={this.state.reached_max_custom_fields}
                                />
                            </div>
                            <hr/>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputTyC">Términos y Condiciones</label>

                                    <textarea class="form-control" id="inputTyC" rows="5" cols="120" onChange={this.onChangeTermsAndConditions} defaultValue={this.state.terms_and_conditions} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputTyCURL">URL del Enlace a Términos y Condiciones</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        id="inputTyCURL"
                                        value={this.state.terms_and_conditions_url}
                                        onChange={this.onChangeTermsAndConditionsURL}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <p>Recordá que la URL para acceder será: <b style={{color: "red"}}>https://{window.location.hostname}/alta_clientes/{this.state.pathname}</b></p>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
    
                <Snackbar open={this.state.open} autoHideDuration={1000} onClose={() => this.setState({success: true})}>
                    <Alert onClose={() => this.setState({success: true})} severity="success">
                        Datos guardados correctamente!
                    </Alert>
                </Snackbar>

            </div>
        )
    }

}

export default StoreNewClient